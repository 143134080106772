var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isVisibleChatBubbles && _vm.isCurrentTime && _vm.employeeSchedule.length && (_vm.isRoleF1 || _vm.isRoleF2) ? _c('div', {
    staticClass: "position-fixed p-0 d-flex align-items-start hover-bubble-chat",
    style: "z-index: 99; cursor: pointer; bottom: ".concat(_vm.isMobileView ? '15%' : '15%', "; right: ").concat(_vm.isMobileView ? '10px' : '3%', "; ")
  }, [_c('div', {
    staticClass: "rounded-circle d-flex-center shadow-info",
    staticStyle: {
      "background": "rgba(244, 249, 255, 1) !important",
      "width": "85px",
      "height": "85px"
    }
  }, [_c('div', {
    staticClass: "rounded-circle d-flex-center align-items-center",
    staticStyle: {
      "background": "rgba(207, 230, 255, 1) !important",
      "width": "75px",
      "height": "75px"
    }
  }, [_c('b-dropdown', {
    ref: "dropdownRef",
    staticClass: "custom_class",
    staticStyle: {
      "height": "60px",
      "width": "60px"
    },
    attrs: {
      "size": "lg",
      "variant": "none",
      "toggle-class": "text-decoration-none p-0",
      "dropup": "",
      "menu-class": "border-0",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('IAmIcon', {
          staticClass: "bud-button",
          staticStyle: {
            "color": "rgba(0, 122, 255, 1)"
          },
          attrs: {
            "icon": "bubbleIcons",
            "size": "35"
          }
        })];
      },
      proxy: true
    }], null, false, 679341929)
  }, [_c('transition', {
    attrs: {
      "name": "dropdown-slide"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dropdownVisible,
      expression: "dropdownVisible"
    }]
  }, [_c('div', {
    staticClass: "shadow-info bg-white py-lg-1",
    staticStyle: {
      "max-height": "245px",
      "overflow": "auto",
      "border-top-right-radius": "14px",
      "border-top-left-radius": "14px",
      "border-bottom-right-radius": "14px"
    }
  }, _vm._l(_vm.employeeSchedule, function (schedule, index) {
    return _c('div', {
      key: index
    }, [_c('b-dropdown-item', {
      staticClass: "p-0",
      attrs: {
        "target": "_blank"
      },
      on: {
        "click": function click($event) {
          return _vm.openTabZaloSchedule(index);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex-center gap-1 justify-content-between"
    }, [_c('span', {
      staticStyle: {
        "font-weight": "700",
        "font-size": "16px",
        "color": "rgb(236, 32, 41)"
      }
    }, [_vm._v(_vm._s(schedule.employee.phoneNumber))]), _c('span', {
      staticClass: "ml-1",
      staticStyle: {
        "white-space": "nowrap !important",
        "overflow": "hidden !important",
        "text-overflow": "ellipsis !important",
        "font-weight": "400",
        "font-size": "16px"
      }
    }, [schedule.employee.type === 'SE' ? _c('span', [_vm._v("Sale")]) : _vm._e(), _vm._v(" " + _vm._s(schedule.employee.lastName) + " " + _vm._s(schedule.employee.firstName) + " ")]), _c('IAmIcon', {
      attrs: {
        "icon": "zalo",
        "color": "blue",
        "size": "18"
      }
    })], 1)])], 1);
  }), 0), _c('div', {
    staticClass: "support-text"
  }, [_c('b-button', {
    staticClass: "text-white text-bold rounded-pill",
    staticStyle: {
      "background": "rgba(0, 122, 255, 1) !important"
    }
  }, [_vm._v(" Bạn cần hỗ trợ? "), _c('span', {
    staticClass: "contact-link"
  }, [_vm._v("Vui lòng liên hệ")])])], 1)])])], 1)], 1)]), _c('b-button', {
    staticClass: "p-0 align-baseline hover-close-bubble-chat",
    attrs: {
      "variant": "none"
    },
    on: {
      "click": _vm.closeBubbleChat
    }
  }, [_c('IAmIcon', {
    staticClass: "text-danger",
    attrs: {
      "icon": "closeOutline",
      "size": "18"
    }
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }