<template>
  <div
    v-if="isVisibleChatBubbles && isCurrentTime && employeeSchedule.length && (isRoleF1 || isRoleF2)"
    class="position-fixed p-0 d-flex align-items-start hover-bubble-chat"
    :style="`z-index: 99; cursor: pointer; bottom: ${isMobileView ? '15%' : '15%'}; right: ${isMobileView ? '10px' : '3%'}; `"
  >
    <div
      class="rounded-circle d-flex-center shadow-info"
      style="background:rgba(244, 249, 255, 1) !important; width: 85px; height:85px "
    >
      <div
        style="background: rgba(207, 230, 255, 1) !important; width: 75px; height:75px"
        class="rounded-circle d-flex-center align-items-center"
      >
        <b-dropdown
          ref="dropdownRef"
          size="lg"
          variant="none"
          style=" height: 60px; width: 60px;"
          toggle-class="text-decoration-none p-0"
          class="custom_class"
          dropup
          menu-class="border-0"
          no-caret
        >
          <template #button-content>
            <IAmIcon
              style="color: rgba(0, 122, 255, 1);"
              icon="bubbleIcons"
              class="bud-button"
              size="35"
            />
          </template>
          <transition name="dropdown-slide">
            <div v-show="dropdownVisible">
              <div
                style="max-height: 245px; overflow:auto; border-top-right-radius: 14px; border-top-left-radius: 14px; border-bottom-right-radius: 14px;"
                class="shadow-info bg-white py-lg-1"
              >
                <div
                  v-for="(schedule, index) in employeeSchedule"
                  :key="index"
                >
                  <b-dropdown-item
                    target="_blank"
                    class="p-0"
                    @click="openTabZaloSchedule(index)"
                  >
                    <div class="d-flex-center gap-1 justify-content-between">
                      <span style="font-weight:700; font-size:16px; color: rgb(236, 32, 41)">{{ schedule.employee.phoneNumber }}</span>
                      <span
                        class="ml-1"
                        style="white-space: nowrap !important; overflow: hidden !important; text-overflow: ellipsis !important; font-weight: 400; font-size: 16px"
                      >
                        <span v-if="schedule.employee.type === 'SE'">Sale</span> {{ schedule.employee.lastName }} {{ schedule.employee.firstName }}
                      </span>
                      <IAmIcon
                        icon="zalo"
                        color="blue"
                        size="18"
                      />
                    </div>
                  </b-dropdown-item>
                </div>
              </div>
              <div class="support-text">
                <b-button
                  style=" background: rgba(0, 122, 255, 1) !important;"
                  class="text-white text-bold rounded-pill"
                >
                  Bạn cần hỗ trợ? <span class="contact-link">Vui lòng liên hệ</span>
                </b-button>
              </div>
            </div>
          </transition>
        </b-dropdown>
      </div>
    </div>

    <b-button
      variant="none"
      class="p-0 align-baseline hover-close-bubble-chat"
      @click="closeBubbleChat"
    >
      <IAmIcon
        icon="closeOutline"
        class="text-danger"
        size="18"
      />
    </b-button>
  </div>
</template>

<script>
import {
  ref, onMounted, computed, nextTick, onUnmounted, watch,
} from '@vue/composition-api'
import { BButton, BDropdown, BDropdownItem } from 'bootstrap-vue'
import moment from 'moment'

import { env } from '@/libs/env'
import store from '@/store'
import { apiSchedule } from '@/api/'
import { getMoment } from '@/@core/utils/filter'

export default {
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
  },
  setup() {
    const { isDevelopment } = env
    const dropdownRef = ref(null)
    const PER_PAGE = 100
    const CURRENT_PAGE = 1
    const intervalId = ref()
    const sortBy = ref('employee')
    const isSortDirDesc = ref(true)
    const isVisibleChatBubbles = ref(true)
    const employeeSchedule = ref([])
    const workDate = getMoment(new Date()).format('YYYY-MM-DD')
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const scheduleType = computed(() => store.getters['app-work-schedule/getScheduleType'])
    const isToday = computed(() => store.getters['app-work-schedule/getWorkDate'] === moment().format('YYYY-MM-DD'))
    const TODAY_START_TIME = ref(isDevelopment ? (localStorage.getItem('todayStarttime') ?? '23:00') : '23:00')

    const TODAY_END_TIME = ref(isDevelopment ? (localStorage.getItem('todayEndtime') ?? '23:59') : '23:59')

    const TOMORROW_START_TIME = ref(isDevelopment ? (localStorage.getItem('toMorrowStarttime') ?? '02:50') : '00:00')

    const TOMORROW_END_TIME = ref(isDevelopment ? (localStorage.getItem('toMorrowEndtime') ?? '06:50') : '02:30')

    const timeToMinutes = time => {
      const [hours, minutes] = time.split(':').map(Number)
      return hours * 60 + minutes
    }

    const TIME_RANGE = [
      { start: timeToMinutes(TODAY_START_TIME.value), end: timeToMinutes(TODAY_END_TIME.value) },
      { start: timeToMinutes(TOMORROW_START_TIME.value), end: timeToMinutes(TOMORROW_END_TIME.value) },
    ]

    const isScheduleInSpecialTime = ({ startTime, endTime }) => {
      const startMinutes = timeToMinutes(startTime)
      const endMinutes = timeToMinutes(endTime)
      return TIME_RANGE.some(range => (startMinutes >= range.start || endMinutes <= range.end))
    }

    const filterEmployeesBySchedule = (schedules = employeeSchedule.value) => schedules.filter(emp => emp.isActive
        && emp.scheduleCalendars.some(sch => sch.isActive
          && sch.scheduleCalendarTimes.some(time => isScheduleInSpecialTime(time))))

    const isAlreadyFetchSchedules = ref(false)
    const fetchSchedules = async () => {
      if (isAlreadyFetchSchedules.value) {
        return
      }
      isAlreadyFetchSchedules.value = true
      try {
        const res = await apiSchedule.fetchSchedules({
          size: PER_PAGE,
          page: CURRENT_PAGE,
          sortBy:
            sortBy.value === 'scheduleCalendarTimes'
              ? 'scheduleCalendars'
              : sortBy.value === 'type'
                ? 'scheduleTags'
                : 'employee',
          sortDirection: isSortDirDesc.value ? 'ASC' : 'DESC',
          startDate: workDate,
          endDate: workDate,
          isOnline: isToday.value ? !scheduleType.value : undefined,
          isParent: true,
        })
        employeeSchedule.value = filterEmployeesBySchedule(res.data.items)
      } catch {
        console.error('Không thấy data!')
      }
    }
    const isCurrentTime = ref(false)
    const closeBubbleChat = () => {
      isVisibleChatBubbles.value = false
    }
    const openTabZaloSchedule = index => {
      if (employeeSchedule.value.length && index >= 0 && index < employeeSchedule.value.length) {
        const phoneNumber = employeeSchedule.value[index]?.employee?.phoneNumber
        if (phoneNumber) {
          window.open(`https://zalo.me/${phoneNumber}`, '_blank')
        } else {
          console.warn('Số điện thoại không hợp lệ!')
        }
      }
    }
    const checkTimeInterval = () => {
      if (!isVisibleChatBubbles.value) {
        clearInterval(intervalId.value)
        return
      }
      const now = new Date()
      const currentMinutes = now.getHours() * 60 + now.getMinutes()

      const isInTimeRanges = (currentMinutes >= timeToMinutes(TODAY_START_TIME.value) && currentMinutes <= timeToMinutes(TODAY_END_TIME.value))
      || (currentMinutes >= timeToMinutes(TOMORROW_START_TIME.value) && currentMinutes <= timeToMinutes(TOMORROW_END_TIME.value))
      isCurrentTime.value = isInTimeRanges
    }

    watch([isCurrentTime, employeeSchedule, isRoleF1, isRoleF2], async ([currentTime, schedule]) => {
      if (currentTime && schedule.length === 0 && (isRoleF1.value || isRoleF2.value)) {
        await fetchSchedules()
        nextTick(() => {
          if (dropdownRef.value && employeeSchedule.value.length) {
            dropdownRef.value.show()
          }
        })
      }
    },
    { deep: true, immediate: true })

    onMounted(() => {
      intervalId.value = setInterval(checkTimeInterval, 1000)
      checkTimeInterval()
    })

    onUnmounted(() => {
      clearInterval(intervalId.value)
    })

    const dropdownVisible = computed(() => dropdownRef.value?.visible ?? false)

    return {
      fetchSchedules,
      isCurrentTime,
      closeBubbleChat,
      employeeSchedule,
      workDate,
      isVisibleChatBubbles,
      openTabZaloSchedule,
      dropdownRef,
      isRoleF1,
      isRoleF2,
      dropdownVisible,
    }
  },
}
</script>

<style lang="css" scoped>
.bud-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: rgba(171, 211, 255, 1) !important;
  animation: budGlow 2s infinite ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.bud-button:hover {
  box-shadow: 0 0 15px rgba(0, 122, 255, 0.7);
  transform: scale(1.1);
}

@keyframes budGlow {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.bud-button::before {
  content: "";
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  opacity: 0.6;
  z-index: -1;
  animation: budGlow 2s infinite;
}
.custom_class::v-deep{
  .dropdown-menu{
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    top: -10px !important;
    left: -57px !important;
    max-width: 300px !important;
  }
}

.shadow-info {
  box-shadow: 0px 4px 10px rgba(23, 162, 184, 0.5);
}
.support-text {
  .btn {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    box-shadow: 0px 4px 10px rgba(23, 162, 184, 0.5);
  }
}

.contact-link {
  margin-left: 5px;
  text-decoration: none;
  font-style: italic;
}

.hover-bubble-chat {
  &:hover {
    .hover-close-bubble-chat {
      display: block;
    }
  }
  .hover-close-bubble-chat {
    display: none;
    position: absolute;
    top: -7px;
    right: -7px;
    background-color: transparent;
    border-radius: 50%;
    padding: 7px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.dropdown-slide-enter-active {
  transition: all 0.6s ease;
}

.dropdown-slide-enter {
  transform: translateY(20px);
  opacity: 0;
}
</style>
